<template>
  <div class="notfound">
    <div class="imgdiv">
      <h5>页面不见了</h5>
      <div class="backbtn" @click="backtologin">回到登录</div>
      <img src="../assets/503.png" alt="503">
    </div>
  </div>
</template>
<script>
export default {
  methods:{
    backtologin(){
      this.$router.push({
        path: '/Login'
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.notfound{
  .imgdiv{
    width: 372px;
    height: 520px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -186px;
    margin-top: -260px;
    h5{
      font-size:32px;
      color:#dadada;
      text-align:center;
      margin-bottom:24px;
    }
    .backbtn{
      font-size:16px;
      color:#0000ee;
      text-align:center;
      margin-bottom:36px;
    }
    .backbtn:hover{
      text-decoration: underline;
    }
  }
}
</style>